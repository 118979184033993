import Body from '../components/Body';


export default function PrivacyPolicyPage() {
  return (
    <Body>
      <div>
        <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> July 1, 2024</p>

        <h2>Introduction</h2>
        <p>Welcome to Purely Social ("we," "our," "us"). We are committed to protecting your privacy and ensuring that
          your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we
          collect, use, disclose, and protect your information when you use our services.</p>

        <h2>1. Information We Collect</h2>
        <p>We collect information to provide better services to our users. The types of information we collect
          include:</p>
        <ul>
          <li><strong>Personal Information:</strong> Information you provide us directly such as your name, email
            address, and payment information.
          </li>
          <li><strong>Usage Data:</strong> Information about how you use our services, such as the types of content you
            view or interact with, the frequency and duration of your activities, and other related information.
          </li>
          <li><strong>Device Information:</strong> Information about the devices you use to access our services,
            including the type of device, operating system, browser type, and IP address.
          </li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Provide, maintain, and improve our services.</li>
          <li>Process transactions and send you related information.</li>
          <li>Communicate with you about updates and other news related to Purely Social.</li>
          <li>Monitor and analyze trends, usage, and activities in connection with our services.</li>
        </ul>

        <h2>3. Information Sharing and Disclosure</h2>
        <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.
          However, we may share your information in the following circumstances:</p>
        <ul>
          <li><strong>With Service Providers:</strong> We may share your information with third-party service providers
            who perform services on our behalf, such as payment processing and data analysis.
          </li>
          <li><strong>For Legal Reasons:</strong> We may disclose your information if required to do so by law or in
            response to a legal request, such as a subpoena, court order, or government demand.
          </li>
        </ul>

        <h2>4. Data Security</h2>
        <p>We implement a variety of security measures to maintain the safety of your personal information. However, no
          security system is impenetrable, and we cannot guarantee the absolute security of your information.</p>

        <h2>5. Your Choices</h2>
        <p>You have choices regarding the collection and use of your information:</p>
        <ul>
          <li><strong>Access and Update:</strong> You can access and update your personal information through your
            profile page.
          </li>
          <li><strong>Opt-Out:</strong> You can opt-out of receiving promotional communications from us by following the
            unsubscribe instructions in those communications.
          </li>
        </ul>

        <h2>6. Children's Privacy</h2>
        <p>Our services are not intended for individuals under the age of 13 without parental consent. Parents or legal
          guardians can create an account for their child under the age of 13. We will collect personal information from
          the child as provided by the parent or guardian. If we become aware that a child under 13 has provided us with
          personal information without parental consent, we will take steps to delete such information.</p>

        <h2>7. Account Deletion</h2>
        <p>If you choose to close your account, we will delete your account and all associated posts. All information
          will be permanently deleted after our backup systems expire, which is 90 days from the date of account
          closure.</p>

        <h2>8. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
          Privacy Policy on our website. Your continued use of our services after any changes to the Privacy Policy
          constitutes your acceptance of the new terms.</p>

        <h2>9. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p>Email: <a href="mailto:admin@purelysocial.net?subject=Privacy%20Policy%20">admin@purelysocial.net</a></p>
      </div>
    </Body>
  );
}
