import Container from 'react-bootstrap/Container';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';

import FlashProvider from './contexts/FlashProvider';
import ApiProvider from './contexts/ApiProvider';
import Header from './components/Header';

import WelcomePage from './pages/WelcomePage';
import HomePage from './pages/HomePage';
import FriendsPage from './pages/FriendsPage';
import ProfilePage from './pages/ProfilePage';
import TermsConditionsPage from './pages/TermsConditionsPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import TestPage from './pages/TestAPIPage';

import {AuthProvider} from './contexts/AuthContext';


export default function App() {
  return (
    <Container fluid className="App">
      <BrowserRouter>
        <FlashProvider>
          <ApiProvider>
            <AuthProvider>
              <Header/>
              <Routes>
                <Route path="/test" element={
                  <PublicRoute><TestPage/></PublicRoute>}/>
                <Route path="/" element={
                  <PublicRoute><WelcomePage/></PublicRoute>}/>
                <Route path="/privacypolicy" element={
                  <PublicRoute><PrivacyPolicyPage/></PublicRoute>}/>
                <Route path="/termsandconditions" element={
                  <PublicRoute><TermsConditionsPage/></PublicRoute>
                }/>
                <Route path="*" element={
                  <PrivateRoute>
                    <Routes>
                      <Route path="/home" element={<HomePage/>}/>
                      <Route path="/friends" element={<FriendsPage/>}/>
                      <Route path="/profile" element={<ProfilePage/>}/>
                      <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                  </PrivateRoute>
                }/>
              </Routes>
            </AuthProvider>
          </ApiProvider>
        </FlashProvider>
      </BrowserRouter>
    </Container>
  );
}
