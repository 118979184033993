import Body from '../components/Body';
import Posts from '../components/Posts';

export default function FriendsPage() {
  return (
    <Body sidebar>
      {/*<Posts content="explore"/>*/}
      <h1>This is Friends page</h1>
    </Body>
  );
}
