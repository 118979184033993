import {useApi} from '../contexts/ApiProvider';
import Spinner from "react-bootstrap/Spinner";
import {useEffect, useState} from "react";


export default function TestPage() {
  const api = useApi();
  const [posts, setPosts] = useState();
  let url = ''
  useEffect(() => {
    (async () => {
      const response = await api.get(url);
      if (response.ok) {
        setPosts(response.body.data);
      } else {
        setPosts(null);
      }
    })();
  }, [api, url]);
  return (
    <>
      TEST API PAGE</>
    // <>
    //   {posts === undefined ?
    //       <Spinner animation="border" />
    //       :
    //       <>
    //         {posts === null ?
    //             <p>Could not retrieve blog posts.</p>
    //             :
    //             <>
    //               Here
    //             </>
    //         }
    //       </>
    //   }
    // </>
  );
}
