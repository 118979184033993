import Body from '../components/Body';
import {NavLink} from 'react-router-dom';
import Nav from "react-bootstrap/Nav";


export default function WelcomePage() {
  return (
    <Body>
      <div>
        <h1>Welcome to Purely Social - Your Space, Your Rules</h1>
        <p>Rediscover Genuine Connections: At Purely Social, we believe in the power of privacy. You pay for
          your peace of mind, not with your personal data. That’s why we’ve designed a platform where your
          videos, pictures, and posts are shared only with the people you choose. No ads, no data selling—just
          you and your friends, connecting in a space that respects your privacy.</p>
        <p>Own Your Experience: Here, you own everything you post. 'Purely' isn't just part of our name; it's
          our promise. Purely Social is purely yours. With us, your content stays your content. Share your
          life with your friends—on your terms.</p>
        <p>Connect on Your Terms: Enjoy the freedom to express, share, and engage in a community where the only
          audience is the one you invite. Our subscription model ensures that our priorities align with your
          needs, not advertisers’.</p>
        <p>Be Social, Stay Private: Dive into a world of social networking where being social doesn't mean being
          an open book. From day one, your privacy is our priority. This is social media the way it should
          be—safe, secure, and solely yours.</p>
        <p>Join Purely Social: Because privacy isn't just a feature, it's a right.</p>
        <Nav.Link as={NavLink} to="/termsandconditions" className="terms-link">Terms and Conditions</Nav.Link>
      </div>
    </Body>
  );
}
