const theme = {
  colors: {
    text: '#7AB5E2', // text
    background: '#DEECEF', // background
    button: '#32628A',
    light_blue: '#9FCCF0',
  },
  fonts: {
    base: 'Lato, sans-serif',
  },
};

export default theme;
