import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function PrivateRoute({ children }) {
  const { isAuthenticated, isAuthChecked } = useAuth();
  const location = useLocation();

  if (!isAuthChecked) {
    // Optionally, you can return a loading spinner or some other placeholder while checking authentication
    return <div>Loading...</div>;
  }

  if (isAuthenticated()) {
    return children;
  } else {
    return <Navigate to="/" state={{ from: location }} />;
  }
}
