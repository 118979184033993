import React, { createContext, useContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import config from '../config';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState(null);
  const [isAuthChecked, setIsAuthChecked] = useState(false); // State to indicate if auth check is done

  useEffect(() => {
    const checkAuthentication = () => {
      const urlParams = new URLSearchParams(window.location.hash.substring(1));
      const token = urlParams.get('id_token');
      if (!token) {
        setIsAuthChecked(true); // Auth check done
        return;
      }

      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          // Token has expired
          setIsAuthChecked(true); // Auth check done
          return;
        }
        const decodedUsername = decodedToken['cognito:username'];
        setUsername(decodedUsername);
        setIsAuthChecked(true); // Auth check done
      } catch (error) {
        console.error('Error validating JWT token:', error);
        // Token is invalid
        setIsAuthChecked(true); // Auth check done
      }
    };

    checkAuthentication();
  }, []);

  const isAuthenticated = () => {
    return username !== null;
  };

  const login = () => {
    const loginUrl = `https://${encodeURIComponent(config.authenticationDomain)}/login?client_id=${config.clientId}&response_type=token&scope=email+openid+phone&redirect_uri=${encodeURIComponent(config.redirectLoginUri)}`
    window.location.href = loginUrl;
  };

  const logout = () => {
    const logoutUrl = `https://${encodeURIComponent(config.authenticationDomain)}/logout?client_id=${config.clientId}&logout_uri=${encodeURIComponent(config.redirectLoginUri)}`;
    window.location.href = logoutUrl;
    setUsername(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, username, isAuthChecked }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
