import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import { NavLink } from 'react-router-dom';
import theme from '../theme';
import { useAuth } from '../contexts/AuthContext';

export default function Header() {
  const { isAuthenticated, login, logout, username, isAuthChecked } = useAuth();

  return (
    <Navbar expand="lg" sticky="top" className="Header">
      <Container>
        <Navbar.Brand
          className="d-flex align-items-center"
          style={{ color: theme.colors.text, fontSize: '3rem', lineHeight: '1' }}
        >
          <img
            src="https://purelysocial.s3.us-east-2.amazonaws.com/purelysocial_icon.webp"
            alt="logo"
            style={{ width: '25%', marginRight: '0.5rem' }}
          />
          Purely Social
        </Navbar.Brand>
        <Nav>
          {!isAuthChecked ? (
            <Spinner animation="border" />
          ) : (
            <>
              {isAuthenticated() ? (
                <div className="justify-content-end">
                  <NavDropdown
                    title={
                      <Image
                        src={
                          '/Users/stmosher/WebstormProjects/react-microblogJune/src/assets/purelysocial_icon.webp' +
                          '&s=32'
                        }
                        roundedCircle
                      />
                    }
                    align="end"
                  >
                    <NavDropdown.Item as={NavLink} to={'/profile'}>
                      Profile
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    {/*<NavDropdown.Item as={NavLink} to="/password">*/}
                    {/*  Change Password*/}
                    {/*</NavDropdown.Item>*/}
                    <NavDropdown.Item onClick={logout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              ) : (
                <Nav.Link onClick={login} style={{ color: theme.colors.text }}>
                  Login/Join
                </Nav.Link>
              )}
            </>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
}
