import Body from '../components/Body';
import Posts from '../components/Posts';

export default function HomePage() {
  return (
    <Body sidebar>
      {/*<Posts write={true} />*/}
      {/*<Posts />*/}
      <h1>This is home page</h1>
    </Body>
  );
}
