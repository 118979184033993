import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function PublicRoute({ children }) {
  const { username, isAuthChecked } = useAuth();

  if (!isAuthChecked) {
    // Optionally, you can return a loading spinner or some other placeholder while checking authentication
    return <div>Loading...</div>;
  }

  if (username) {
    return <Navigate to="/welcome" />;
  } else {
    return children;
  }
}
